import cn from 'classnames';
import {
  Button,
  Calendar,
  Checkbox,
  Label,
  MenuItem,
  ProgressBar,
  Select,
  TextField
} from 'mw-style-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import UtilsApi from '../../utils/api';
import DateUtils from '../../utils/dateUtils';
import Utils from '../../utils/utils';
import './Form.scss';

class Form extends PureComponent {
  constructor(props) {
    super(props);
    const fields = props.data.fields || [];
    let isSend = false;

    if (typeof window !== 'undefined' && props.data.type === 'autoSend') {
      const params = Utils.getQueryParam(location.search);
      const paramsArray = [];
      if (!params.email) {
        location.href = `${location.origin}/form/support`;
        return;
      }

      Object.keys(params).forEach(key => {
        paramsArray.push({key, value: params[key]});
      });
      isSend = true;
      const body = {
        form_id: 'autoSend',
        action: 'submit',
        fields: paramsArray
      };

      UtilsApi.sendForm(body, props.data.link, () => {
        if (typeof window !== 'undefined' && window.gtag) {
          window.gtag('event', 'submit', {
            event_category: 'inbound',
            event_label: window.location.pathname,
          });
        }
      });
    }

    this.state = {
      fields,
      errors: [],
      load: false,
      errorSend: false,
      isSend,
    };

    this.renderCalendar = this.renderCalendar.bind(this);
  }

  componentDidMount() {
    const { fields } = this.state;
    const { data } = this.props;
    const login = localStorage.getItem('userEmail');
    if (login) {
      // подставлем email если пользователь залогинен
      fields.forEach(f => {
        if (!f.value && f.type === 'email') {
          f.value = login;
          this.forceUpdate();
        }
      });
    }

    if (data.type !== 'autoSend' && typeof window !== 'undefined' && window.location.hash) {
      const hash = window.location.hash.slice(1).split('=');
      const field = hash[0];
      let value = hash[1];
      if (hash[1].slice(-1) === '/') {
        value = hash[1].slice(0, hash[1].length - 1);
      }
      fields.forEach(f => {
        if (f.type === field) {
          if (f.list) {
            f.list.forEach(l => {
              if (l.type === value) {
                f.value = l.value;
              }
            });
          } else {
            f.value = value;
          }
          this.forceUpdate();
        }
      });
    }
  }

  componentWillUnmount() {
    this.resetFields();
  }

  componentWillReceiveProps(newProps) {
    const { type, button } = this.props.data;
    if (newProps.forceSend) {
      this.sendForm(type, button.link);
    }
  }

  handleChange(data) {
    const { fields, errors } = this.state;
    const copyFields = fields.slice();
    const fieldIndex = fields.findIndex(f => f.type === data.id);
    if (data.id === 'date') {
      copyFields[fieldIndex].value =
        `${DateUtils.toDate(data.value.unix, 'DD.MM.YY')} ${DateUtils.toDate(data.value.unix, 'HH:mm')}`;
      copyFields[fieldIndex].unix = data.value.unix;
    } else if (data.id === 'interested' || data.id === 'unsubscribe') {
      copyFields[fieldIndex].list.forEach(item => {
        if (data.key === 'unsubscribed') {
          if (item.key === 'unsubscribed') {
            item.value = !item.value;
          } else {
            item.value = false;
          }
        } else if (item.key === data.key) {
          item.value = !item.value;
        } else if (item.key === 'unsubscribed') {
          item.value = false;
        }
      });
    } else {
      copyFields[fieldIndex].value = data.value;
    }

    // is error
    const copyErrors = errors.slice();
    if (copyErrors.indexOf(data.type) !== -1) {
      copyErrors.splice(copyErrors.findIndex(e => e === data.type), 1);
    }

    if (data.error) {
      copyErrors.push(data.type);
    }

    this.setState({
      fields: copyFields,
      errors: copyErrors,
      errorSend: false
    });
  }

  renderCalendar(unix) {
    const date = unix || new Date().getTime() / 1000;
    return (
      <Calendar
        className={'form__calendar'}
        value={{unix: date, timeZoneOffset: -180}}
        time={true}
        onChange={(data) => this.handleChange(data)}
        id={'date'}
        size={'small'}
        minValue={new Date().getTime() / 1000}
      />
    );
  }

  renderFields() {
    const { intl, data } = this.props;
    const {fields, errors} = this.state;
    return fields.map((field, index) => {
      const error = errors.indexOf(field.type) !== -1;
      let helperText = error ? 'field is required' : '';
      let calendar = '';

      if (field.label === 'Email' && error && field.value) {
        helperText = 'don\'t valid email';
      }

      if (field.type === 'interested' || field.type === 'unsubscribe') {
        return (
          <div className="col-12" key={index}>
            <div
              className="form__checkbox__label"
            >
              {field.label &&
                intl.formatMessage({ id: field.label })
              }
            </div>
            <div
              className={
                cn('form__checkbox', {column: field.type === 'unsubscribe'})
              }
            >
              {field.list.map(item => (
                <Checkbox
                  className="form__checkbox__item"
                  key={item.key}
                  value={item.value}
                  onChange={() => this.handleChange({id: field.type, key: item.key})}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: Utils.bbCodeToHtml(item.label && intl.formatMessage({id: item.label}))
                    }}
                  />
                </Checkbox>
              ))}
            </div>
          </div>
        );
      }

      if (field.list) {
        return (
          <Select
            key={field.type}
            className="col-6"
            id={field.type}
            error={error}
            label={intl.formatMessage({id: field.label})}
            value={field.value}
            onChange={(data) => this.handleChange(data)}
          >
            {field.list.map((c, index) => (
              <MenuItem
                key={index}
                label={intl.formatMessage({id: c.value})}
                value={c.value.toString()}
              />
            ))}
          </Select>
        );
      } else if (field.type === 'date') {
        calendar = () => this.renderCalendar(field.unix);
      }

      const className = data.type === 'extendedTrial' ? 'form__describe__big' : 'form__describe';
      const multilineType = ['message', 'projectDescription', 'requirements', 'responsibilities', 'benefits', 'additionalInformation'];
      const isMultiline = multilineType.findIndex(t => t === field.type) !== -1;
      const colSize = field.type === 'employees' ? 'col-12' : 'col-6';
      return (
        <TextField
          key={field.type}
          id={field.type}
          error={error}
          bordered={data.typeInput === 'border'}
          className={isMultiline ? `${className} col-12` : colSize}
          label={field.label && intl.formatMessage({id: field.label})}
          placeholder={field.placeholder && intl.formatMessage({id: field.placeholder})}
          helperText={helperText}
          type={field.label === 'Email' ? 'email' : 'text'}
          value={field.value}
          calendar={calendar}
          length={isMultiline ? 1000 : 40}
          multiline={isMultiline}
          rows={isMultiline ? 4 : 1}
          onChange={(data) => this.handleChange(data)}
          aria-label={field.label && intl.formatMessage({id: field.label})}
          aria-required="true"
          name={field.type}
        />
      );
    });
  }

  closeForm() {
    this.setState({
      isSend: true,
      errorSend: false
    });
  }

  resetFields() {
    const { fields } = this.state;
    const copyFields = fields.slice();

    copyFields.forEach(field => {
      if (field.type === 'interested' || field.type === 'unsubscribe') {
        field.list.forEach(item => (
          item.value = false
        ));
      }
      field.value = '';
    });

    this.setState({
      fields: copyFields,
      load: false
    });
  }

  sendForm(type, url) {
    const { fields } = this.state;
    const { options, data, requiredFields = [] } = this.props;
    const { button } = data;
    const errors = [];
    const body = {
      form_id: type,
      action: 'submit',
      fields: []
    };
    let validEmail = true;

    const requireFields = ['firstName', 'lastName', 'email', 'message', ...requiredFields];


    fields.forEach(field => {
      if (requireFields.indexOf(field.type) !== -1 && !field.value) {
        errors.push(field.type);
      } else if (field.type === 'email') {
        // eslint-disable-next-line
        const re = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
        if (!re.test(String(field.value))) {
          validEmail = false;
        }
      }

      if (field.type === 'email' && !validEmail) {
        errors.push(field.type);
      }

      if (field.type === 'interested' || field.type === 'unsubscribe') {
        let interestedList = '';
        field.list.forEach(item => {
          if (item.value) {
            interestedList += interestedList ? `, ${item.key}` : `${item.key}`;
          }
        });
        body.fields.push({
          key: field.type,
          value: interestedList,
        });
      } else {
        body.fields.push({
          key: field.type,
          value: field.value
        });
      }

      if (field.type === 'unsubscribe') {
        const params = Utils.getQueryParam(location.search);
        const email = localStorage.getItem('userEmail');

        if (params.email) {
          body.fields.push({
            key: 'email',
            value: params.email,
          });
        }

        if (email) {
          body.fields.push({
            key: 'localEmail',
            value: email,
          });
        }
      }

      if (options) {
        body.options = options;
      }
    });

    this.setState({
      errors,
    });

    if (errors.length === 0) {
      this.setState({
        load: true
      });

      Utils.sendEvent(button.event);

      UtilsApi.sendForm(body, url, () => {
        if (typeof window !== 'undefined' && window.gtag) {
          window.gtag('event', 'submit', {
            event_category: 'inbound',
            event_label: window.location.pathname,
          });
        }
        if (this.props.callback) this.props.callback();
        if (this.props.onSuccess) this.props.onSuccess(fields);
        this.closeForm();
        this.resetFields();
      }, () => {
        this.setState({
          load: false,
          errorSend: true
        });
      });
    }
  }

  render() {
    const { intl, data } = this.props;
    const { button, type, title, subText } = data;
    const { load, isSend, errorSend } = this.state;

    if (isSend) {
      if (type === 'unsubscribe') {
        return (
          <div className="form">
            <div className="form__send">
              <Label className="form__title" value={`${intl.formatMessage({id: 'formSuccess'})} 😉`}/>
              <Label className="form__subTitle" value={intl.formatMessage({id: 'formSuccessMassageUnsubscribe'})}/>
            </div>
          </div>
        );
      }

      if (type === 'autoSend' || type === 'modal') {
        return (
          <div className="form">
            <div className="form__send">
              <Label className="form__title" value={`${intl.formatMessage({id: 'formSuccess'})} 😉`}/>
              <Label className="form__subTitle" value={intl.formatMessage({id: 'formSuccessMassageAutoSend'})}/>
            </div>
          </div>
        );
      }

      if (type === 'addVacancy') {
        return (
          <div className="form">
            <div className="form__send">
              <Label className="form__title" value={`${intl.formatMessage({id: 'formSuccess'})} 😉`}/>
            </div>
          </div>
        );
      }

      return (
        <div className="form">
          <div className="form__send">
            <Label className="form__title" value={`${intl.formatMessage({id: 'formSuccess'})} 😉`}/>
            <Label className="form__subTitle" value={intl.formatMessage({id: 'formSuccessMassage'})}/>
          </div>
        </div>
      );
    }

    return (
      <form name={type} className={cn('form', { form__modal: type === 'modal' })}>
        {title &&
          <div className="form__title">
            {intl.formatMessage({id: title})}
          </div>
        }
        <div className="form__fields grid-wrapper">
          {this.renderFields()}
        </div>
        {subText &&
          <div className="form__subText">
            {intl.formatMessage({id: subText})}
          </div>
        }
        {button.text && (
          load ?
            <div className="form__progressBar">
              <ProgressBar value="" />
            </div> :
            <div className="form__buttonWrapper">
              <Button
                className="button__default form__btn"
                label={intl.formatMessage({id: button.text})}
                size={'small'}
                type="submit"
                onClick={() => this.sendForm(type, button.link)}
                aria-label={intl.formatMessage({id: button.text})}
              />
              <button type="submit" aria-label={intl.formatMessage({id: button.text})} style={{display: 'none', visibility: 'hidden'}} onClick={() => this.sendForm(type, button.link)} />
              {errorSend && <span>😞 {intl.formatMessage({id: 'formServerError'})}</span>}
            </div>
        )}
      </form>
    );
  }
}

Form.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  intl: PropTypes.object,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.object,
  callback: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default injectIntl(Form);
